import { useContext, useEffect, useState } from 'react'
import { CheckIcon, ClockIcon } from '@heroicons/react/20/solid'
import { AuthContext } from "../../../Context"
import tinycolor from 'tinycolor2'
import Footer from '../../../Components/Footer/footer'
import { useParams, useNavigate } from 'react-router-dom';
import Spinner from '../../../Components/Spinner'
import Error from '../../../Components/EmptyGrid'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify';


export default function Example() {
    const { states, setters, listarProdutoPorId, getPath } = useContext(AuthContext)
    const [clausulas, setClausulas] = useState([])
    const [linkDownload, setLinkDownload] = useState('')

    const { id } = useParams();
    const navigate = useNavigate()

    const [isChecked, setChecked] = useState(false);

    const handleCheckboxChange = () => {
        setChecked(!isChecked);
    };

    const color = states.companyColor
    const lighterColor = color

    useEffect(() => {
        if (id == null || id == '' || id == undefined) {
            navigate('/')
        }
        else {
            carregarProdutoAsync(id)
        }

    }, [])

    useEffect(() => {
        carregarClausulasAsync(states.productSelected)
    }, [states.productSelected])

    const carregarClausulasAsync = async (listProdutos) => {
        try {
            for await (const produto of listProdutos) {
                if (produto.Clausulas != '' && produto.Clausulas != null && produto.Clausulas != undefined) {
                    const clausulas = produto.Clausulas.split('\n')
                    setClausulas(clausulas)
                }
            }
        }
        catch (err) {
            toast.error('Não foi possível carregar as condições', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }

    const comprarProduto = (deslogado, produto) => {
        if (deslogado) {
            setters.setCompraDeslogado(true)
        }
        else {
            setters.setCompraDeslogado(false)
        }

        sessionStorage.setItem("product", JSON.stringify(produto));
    }

    const carregarProdutoAsync = async (id) => {
        try {
            const path = window.location.pathname
            const id = path.replaceAll('produto', '').replaceAll('/', '')
            const retorno = await listarProdutoPorId(id)
            setLinkDownload(retorno.link)
        }
        catch (err) {
            toast.error(err.response.data.msg, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }

    const corClara = (lighterColor) => {
        return tinycolor(lighterColor).lighten().lighten().toHexString()
    }

    const corrigeClausula = (clausula) => {

        // const regex = /^[^a-zA-Z]+/;
        const extractedText = clausula.replace('\r', '\n')
        return extractedText
    }

    const headerStyle = {
        backgroundImage: color !== ''
            ? `linear-gradient(to right, ${color}, ${corClara(lighterColor)}, ${color})`
            : 'linear-gradient(to right, gray-400, gray-300, gray-400)',
        height: '70px', // Defina a altura desejada
    };

    const [imagem, setImagem] = useState('')

    useEffect(() => {
        constroiLogo()
    }, [])

    const constroiLogo = async () => {
        const origin = await getPath(window)
        setImagem(origin + '/logo.png')
    }

    const downloadFile = (e) => {
        e.preventDefault()

        if (linkDownload == '' || linkDownload == null || linkDownload == undefined) {
            toast.error('Arquivo não disponível para download. Favor entrar em contato com o administrador do sistema')
            return
        }

        const link = document.createElement('a');
        link.href = linkDownload;
        link.target = '_blank';

        // Especifique o nome do arquivo para download
        link.download = linkDownload;

        document.body.appendChild(link);

        // Simule um clique no link para abrir na nova guia
        link.click();

        // Opcional: remover o link após a ação
        document.body.removeChild(link);
    };

    return (
        <>
            <header style={headerStyle}>
                <div className="mx-auto max-w-7xl px-2 sm:px-4 lg:divide-y lg:divide-gray-700 lg:px-8">
                    <div className="relative flex h-16 justify-between">
                        <div className="relative z-10 flex px-2 lg:px-0">
                            <div className="flex flex-shrink-0 items-center">
                                <img
                                    className="h-16 w-auto"
                                    src={states.companyUrl == '' ? imagem : states.companyUrl}
                                    crossOrigin="anonymous"
                                    alt="Sua empresa"
                                />
                            </div>
                        </div>
                        <div className="relative z-0 flex flex-1 items-center justify-center px-2 sm:absolute sm:inset-0">
                            <div className="w-full sm:max-w-xs">
                                <div className="relative">
                                    <div className="flex flex-1 align-center justify-center md:text-sm md:font-semibold md:leading-6 md:text-gray-700"> {states.companyName}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>

            <div className="bg-white">


                <div className="space-y-16 xl:space-y-20 pt-6">
                    <div className="mx-auto max-w-7xl">
                        <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
                            <div className="flex items-center justify-between mb-6">
                                <div className="mt-2 sm:col-span-1 sm:mt-0">
                                    <div className="flex max-w rounded-md">
                                        <div className="flex-1 flex bg-white justify-end items-center">
                                            <button className="text-sm font-semibold leading-6 text-blue-500 hover:text-blue-600" onClick={() => navigate(-1)}>
                                                Voltar
                                            </button>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {states.loading == true ?
                    <Spinner />
                    :
                    states.productSelected[0] != null && states.productSelected[0] != '' && states.productSelected[0] != undefined ?
                        <div className="mx-auto max-w-2xl px-4 py-16 sm:px-6 sm:py-24 lg:grid lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8 mb-10 border rounded-md shadow shadow-md">
                            {/* Product details */}
                            <div className="lg:max-w-lg lg:self-end">
                                <nav aria-label="Breadcrumb">
                                    <ol role="list" className="flex items-center space-x-2">
                                        <li>
                                            <div className="flex items-center text-sm">
                                                <a href="#" className="font-medium text-gray-500 hover:text-gray-900" onClick={() => navigate(-1)}> {/* href="/produtos/externo" */}
                                                    Produtos
                                                </a>
                                                <svg
                                                    viewBox="0 0 20 20"
                                                    fill="currentColor"
                                                    aria-hidden="true"
                                                    className="ml-2 h-5 w-5 flex-shrink-0 text-gray-300"
                                                >
                                                    <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                                                </svg>
                                                <a href={"/produto/" + states.productSelected[0].ID} className="font-medium text-gray-500 hover:text-gray-900">
                                                    {states.productSelected[0].Title}
                                                </a>
                                            </div>
                                        </li>
                                    </ol>
                                </nav>

                                <div className="mt-4">
                                    <h1 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">{states.productSelected[0].Title}</h1>
                                </div>

                                <section aria-labelledby="information-heading" className="mt-4">
                                    <h2 id="information-heading" className="sr-only">
                                        Informações do produto
                                    </h2>

                                    <div className="flex items-center">
                                        <p className="text-lg text-gray-900 sm:text-xl">R$ {parseFloat(states.productSelected[0].Price / 100).toFixed(2).toString().replace('.', ',')}</p>
                                    </div>

                                    <div className="mt-4 space-y-6">
                                        <p className="text-base text-gray-500">{states.productSelected[0].Description}</p>
                                    </div>

                                </section>
                            </div>

                            {/* Product image */}
                            <div className="mt-10 lg:col-start-2 lg:row-span-2 lg:mt-0 lg:self-center">
                                <div className="aspect-h-1 aspect-w-1 overflow-hidden rounded-lg">
                                    {states.productSelected[0].Url == '' ?
                                        (states.companyUrl == '' ?
                                            <img src={imagem} crossOrigin="anonymous" className="h-full w-full object-cover object-center" /> :
                                            <img src={states.companyUrl} crossOrigin="anonymous" className="h-full w-full object-cover object-center" />)
                                        :
                                        <img src={states.productSelected[0].Url} crossOrigin="anonymous" className="h-full w-full object-cover object-center" />
                                    }

                                </div>
                            </div>

                            {/* Product form */}
                            <div className="mt-10 lg:col-start-1 lg:row-start-2 lg:max-w-lg lg:self-start">
                                <section aria-labelledby="options-heading">
                                    <h2 id="options-heading" className="sr-only">
                                        Condições do produto
                                    </h2>

                                    <form>
                                        <div className="mt-4 space-y-6">
                                            <p className="text-base text-gray-500">Condições:</p>
                                        </div>
                                        <div className="sm:flex sm:justify-between">
                                            <div className="mt-4">
                                                {clausulas.length !== 0 &&
                                                    clausulas.map((clausula, index) => {
                                                        const descricao = corrigeClausula(clausula)
                                                        if (clausula !== '') {
                                                            return (
                                                                <div key={index} className="flex items-center">
                                                                    {
                                                                        (index !== 0 && descricao[0] === '*') &&
                                                                        <CheckIcon className="h-5 w-5 flex-shrink-0 text-green-500" aria-hidden="true" />
                                                                    }
                                                                    <p className="ml-2 text-sm text-gray-500">{descricao.replace('*', '')}</p>
                                                                </div>
                                                            )
                                                        }
                                                    })
                                                }
                                            </div>
                                        </div>

                                        <div className="mt-4">
                                            <div className="group inline-flex text-sm text-gray-500">
                                                <span>Duração: {states.productSelected[0].Duration > 1 ? 'meses' : 'mês'}</span>
                                                <ClockIcon
                                                    className=" ml-1 h-5 w-6 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                                                    aria-hidden="true"
                                                />
                                            </div>
                                        </div>
                                        <div className="flex items-center justify-between py-4">
                                            <div className="flex items-center relative">
                                                <input
                                                    id="agreeTerms"
                                                    checked={isChecked}
                                                    onChange={handleCheckboxChange}
                                                    name="agreeTerms"
                                                    type="checkbox"
                                                    className="h-4 w-4 rounded border-gray-300 text-blue focus:ring-blue-600"
                                                />
                                                <label htmlFor="agreeTerms" className="ml-2 block text-center text-sm text-gray-500">
                                                    Aceito os termos e condições
                                                </label>
                                            </div>

                                            <div className="flex items-center relative">
                                                <p className="text-center text-sm text-gray-500">
                                                    Para fazer download dos termos
                                                    {' '}
                                                    <Link onClick={e => downloadFile(e)} className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500">
                                                        clique aqui
                                                    </Link>
                                                </p>
                                            </div>
                                        </div>
                                        <div className="mt-10">
                                            <Link to="/clientes/cadastro" className="relative ml-auto" onClick={() => comprarProduto(true, states.productSelected[0])}>
                                                <button
                                                    disabled={!isChecked}
                                                    type="button"
                                                    title='Ao clicar em comprar, você concorda com os termos do produto'
                                                    className="flex w-full items-center justify-center rounded-md border border-transparent bg-blue-500 px-8 py-3 text-base font-medium text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 focus:ring-offset-gray-50"
                                                >
                                                    Comprar
                                                </button>
                                            </Link>
                                        </div>
                                    </form>
                                </section>
                            </div>
                        </div>
                        :
                        <Error origin={'produto a ser exibido'} />
                }
            </div>

            <Footer />
        </>
    )
}
